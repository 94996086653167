import moment from "moment";
import accounting from "accounting";
import VuetableFieldHandle from "vuetable-2/src/components/VuetableFieldHandle.vue";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence.vue";
import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue";
import common from "../../mixins/common";

export default [{
        name: "id",
        title: "ID",
        sortField: "id",
        width: "5%",
        titleClass: "right aligned",
        dataClass: "right aligned"
    },
    {
        name: "reserved_date",
        sortField: "customer_name",
        title: '<i class="grey list outline icon"></i>Reserved Date',
        width: "10%",
        filterable: true,
        togglable: true
    },
    {
        name: "reserved_time",
        sortField: "reserved_time",
        title: '<i class="grey list outline icon"></i>Reserved Time',
        width: "10%",
        filterable: true,
        togglable: true
    },
    {
        name: "area.name",
        sortField: "area_id",
        title: '<i class="grey list outline icon"></i>Area',
        width: "10%",
        filterable: true,
        togglable: true
    },
    {
        name: "no_of_people",
        sortField: "no_of_people",
        title: '<i class="grey list outline icon"></i>Number of People',
        width: "10%",
        filterable: true,
        togglable: true
    },
    {
        name: "remarks",
        sortField: "remarks",
        title: '<i class="grey list outline icon"></i>Remarks',
        width: "10%",
        filterable: true,
        togglable: true
    },
    {
        name: "status_label",
        title: '<i class="grey list outline icon"></i>Status',
        width: "10%",
        filterable: true,
        togglable: true
    }
];