<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :loader="$store.state.spinerLoader"
      :color="$store.state.spinerColor"
    ></loading>
    <vue-snotify></vue-snotify>
    <CRow class="pt-5 justify-content-center">
      <CCol sm="10" md="10">
        <CCard class="pt-3 pl-3 pr-3 card-color">
          <CRow>
            <CCol md="2">
              <CInput
                type="date"
                label="Reserved Date"
                v-model="reservedDateFil"
              >
                <template #prepend-content
                  ><i class="grey search outline icon"></i
                ></template>
              </CInput>
            </CCol>

            <CCol md="2">
              <CSelect
                type="name"
                autocomplete="name"
                label="Status"
                placeholder="Search in Status"
                :value.sync="statusFil"
                :options="status_options"
                addInputClasses="search-custom"
              >
                <template #prepend-content
                  ><i class="grey list outline icon"></i
                ></template>
              </CSelect>
            </CCol>

            <CCol md="3">
              <div class="mt-4 mb-4">
                <CButton color="primary" class="" @click="doFilter">
                  <CIcon name="cil-magnifying-glass" />
                  Search
                </CButton>

                <CButton
                  color="light"
                  variant="outline"
                  class="ml-3"
                  @click="resetFilter"
                >
                  <CIcon name="cil-pencil" />
                  Clear
                </CButton>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12"> </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="justify-content-center">
      <CCol sm="10" md="10">
        <CCard class="pt-3 pl-3 pr-3 card-color text-white">
          <CRow>
            <CCol md="2">
              <div class="pt-2"></div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <vuetable
                ref="Vuetable"
                :api-url="getUrl"
                :http-options="httpOptions"
                :fields="fields"
                pagination-path
                @vuetable:pagination-data="onPaginationData"
                :per-page="perPage"
                :css="{ sortableIcon: 'grey sort icon' }"
                :append-params="moreParams"
                @vuetable:load-success="loadSuccess"
                @vuetable:load-error="loadError"
                @vuetable:loading="tableLoading"
                class="dark-vue-table"
              >
                <template slot="tableHeader">
                  <vuetable-row-header></vuetable-row-header>
                </template>
                <template slot="custom-actions" scope="props">
                  <div class="form-group">
                    <CDropdown color="primary btn-sm" toggler-text="Actions">
                      <CDropdownItem
                        @click="
                          onActionClicked(
                            'view-order',
                            props.rowData,
                            props.rowIndex
                          )
                        "
                      >
                        <CIcon name="cil-list" class="mr-2" text-white” />
                        <div class="text-white">View Order</div>
                      </CDropdownItem>
                    </CDropdown>
                  </div>
                </template>
              </vuetable>

              <CRow>
                <CCol md="12">
                  <div class="vuetable-pagination ui basic segment grid row">
                    <CCol md="4">
                      <vuetable-pagination-info
                        ref="paginationInfo"
                      ></vuetable-pagination-info>
                    </CCol>
                    <CCol md="8">
                      <vuetable-pagination
                        style="float: right"
                        ref="pagination"
                        @vuetable-pagination:change-page="onChangePage"
                      ></vuetable-pagination>
                    </CCol>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2";
// import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePagination from "@/component/vueTable/VuetablePaginationBootstrap4";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo.vue";
import VuetableRowHeader from "vuetable-2/src/components/VuetableRowHeader.vue";
import FieldsDef from "../MyReservations/ReservationListTableDef";
import MyDetailRow from "@/component/vueTable/MyDetailRow";
import RowFilter from "@/component/vueTable/RowFilter.vue";
import { mapActions, mapState } from "vuex";
import commonMixin from "@/mixins/common";
import store from "../../store/store";

export default {
  name: "OrderList",
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    VuetableRowHeader,
    // RowFilter,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      status: true,
      statusFil: true,
      orderItemsModal: false,
      status_options: [
        {
          value: 0,
          label: "Pending Reservation",
        },
        {
          value: 1,
          label: "Pending Activation",
        },
        {
          value: 2,
          label: "Rejected",
        },
        {
          value: 3,
          label: "Activated",
        },
        {
          value: 4,
          label: "Closed",
        },
      ],
      fields: FieldsDef,
      httpOptions: {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("customer_access_token"),
        },
        data: {},
      },
      moreParams: {},
      getUrl: process.env.VUE_APP_API_ENDPOINT + "/customer/reservation_list",
      detailRow: MyDetailRow,
      paginationComponent: "vuetable-pagination",
      vuetableFields: false,
      loading: "",
      tableHeight: "500px",
      multiSort: true,
      perPage: 10,
      scrollbarVisible: false,
      parent_id: "",
      title: "",
      customerIdFil: "",
      rowId: "",
      orderProcessModal: false,
      orderStatus: "",
      orderStatusLabel: "",
      activeOrder: [],
      activeOrderItem: [],
      reservedDateFil: "",
    };
  },
  async mounted() {},
  computed: {},
  mixins: [commonMixin],
  methods: {
    loadSuccess() {
      //load success event
      this.isLoading = false;
    },
    tableLoading() {
      this.isLoading = true;
    },
    loadError() {
      //table load error
      /* redirect to login */
      this.isLoading = false;
      store.commit("login/clearLogin");
      this.$router.push({
        path: "/login",
      });
    },
    resetCreateForm() {
      this.parent_id = "";
      this.title = "";
    },
    doFilter() {
      this.moreParams = {
        date: this.reservedDateFil,
        status: this.statusFil,
      };
      Vue.nextTick(() => this.$refs.Vuetable.refresh());
    },
    resetFilter() {
      this.reservedDateFil = "";
      this.statusFil = "";
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.Vuetable.refresh());
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.Vuetable.changePage(page);
    },
  },
};
</script>

<style>
select.search-custom option {
  background-color: black !important;
}
.form-control {
  border: 1px solid;
  color: #ffffff !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.c-main {
  background-color: rgba(0, 0, 0, 0.808);
}

.dark-vue-table tr td{
  color: #ffffff !important; 
}
.dark-vue-table thead th{
  color: #ffffff !important; 
}
</style>

<style scoped>
.card-color {
  background-color: rgb(0, 0, 0);
}

.order-table {
  background-color: rgb(0, 0, 0);
}
</style>
