<template>
  <div>
    <MyReservations />
  </div>
</template>

<script>
import MyReservations from "@/component/MyReservations/MyReservations.vue";

export default {
  name: "MyReservationsView",
  components: {
    MyReservations
  }
};
</script>